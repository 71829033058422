<template>
  <div>
    <button class="btn" @click="fullScreen">
      {{isFullscreen?'退出全屏':'进入全屏'}}
    </button>
    <div class="game-con">
      <audio ref="musicClick" src="@/assets/audio/game2/click.mp3"></audio>
      <audio
        ref="instruction"
        src="@/assets/audio/game3/instruction.mp3"
      ></audio>
      <div class="game-top">
        <div class="game-title"></div>
        <div class="game-instruction" @click="showMask"></div>
      </div>
      <ul class="game-items">
        <li
          v-for="(item, index) in items"
          :key="index"
          @click="handleItem(index)"
          :class="[item.act ? 'open' : 'close', 'item' + index]"
        >
          <p class="item-img-box">
            <img class="item-img" :src="preSrc + item.src" alt="" />
          </p>
        </li>
      </ul>
      <!-- 弹窗begin -->
      <transition name="fade">
        <div
          class="mask-container"
          :class="[isShowMask ? 'mask-show' : 'mask-hide']"
          v-if="isShowMask"
        >
          <div class="mask-bg" @click="hideMask"></div>
          <div
            class="mask-con"
            :style="{
              background:
                'url(' + preSrc + instructionPic + ')center center no-repeat',
            }"
          >
          </div>
        </div>
      </transition>
      <!-- 弹窗end -->
    </div>
  </div>
</template>
<script>
import screenfull from "screenfull";
export default {
  data() {
    return {
      preSrc: config.src,
      isShowMask: false,
      items: [],
      instructionPic: "",
      isFullscreen: false,
    };
  },
  created() {
    // 如果有configId就是用户创建的游戏
    let _self = this;
    let configId = _self.$route.params.configId;
    let gameId = _self.$route.params.gameId;
    let title = _self.$route.params.title;
    title ? (document.title = title) : "";
    if (configId) {
      _self.initData(configId);
      return;
    } else {
      _self.getInfo(gameId);
    }
  },
  methods: {
    // 创建的游戏数据
    getInfo(id) {
      let _self = this;
      _self.axios
        .post(config.url.gameInfo, _self.$qs.stringify({ game_id: id }))
        .then((res) => {
          if (res.code == 200) {
            _self.instructionPic = res.data.instruction;
            let config = res.data.pic;
            if (config.length > 0) {
              config.forEach((item) => {
                let obj = {
                  act: true,
                  src: item,
                };
                _self.items.push(obj);
              });
            }
          }
        });
    },
    // 默认数据
    initData(id) {
      let _self = this;
      _self.axios
        .post(config.url.gamePics, _self.$qs.stringify({ config_id: id }))
        .then((res) => {
          if (res.code == 200) {
            _self.instructionPic = res.data.instruction;
            if (res.data.config.length <= 0) {
              return;
            }
            let config = res.data.config;
            // 先清空items在放数据进来
            _self.items = [];
            config.forEach((item) => {
              let temp = {
                act: true,
                src: item,
              };
              _self.items.push(temp);
            });
          }
        });
    },
    //隐藏或显示item
    handleItem(index) {
      let _self = this;
      _self.$refs.musicClick.play();
      _self.items[index].act = !_self.items[index].act;
    },
    //展示弹窗
    showMask() {
      let _self = this;
      _self.$refs.instruction.play();
      _self.isShowMask = true;
    },
    hideMask() {
      let _self = this;
      _self.$refs.instruction.play();
      _self.isShowMask = false;
    },
    // 全屏
    fullScreen() {
      this.isFullscreen = !this.isFullscreen;
      // 如果不允许进入全屏，发出不允许提示
      if (!screenfull.isEnabled) {
        this.$message({
          message: "您的浏览器不支持全屏",
          type: "warning",
        });
        return false;
      }
      screenfull.toggle();
    },
  },
  mounted() {
    if (screenfull.isEnabled) {
      screenfull.on("change", () => {
        this.isFullscreen = screenfull.isFullscreen;
      });
    }
  },
};
</script>
<style scoped>
.line {
  width: 100%;
  height: 60px;
}
.btn {
  margin: 60px auto 0;
}
.game-con {
  width: 1280px;
  height: 720px;
  margin: 30px auto 0;
  background: url("../../assets/img/game2/bg.png") center center no-repeat;
  background-size: 100%;
  padding: 20px 40px;
  box-sizing: border-box;
}
.game-top {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.game-title {
  width: 460px;
  height: 109px;
  background: url("../../assets/img/game2/title.png") center center no-repeat;
  background-size: 100%;
  margin-right: 180px;
  /* 解决缩小图片后，图片变模糊 */
  image-rendering: -moz-crisp-edges;
  image-rendering: -moz-crisp-edges;
  image-rendering: -webkit-optimize-contrast;
  image-rendering: crisp-edges;
  -ms-interpolation-mode: nearest-neighbor;
}
.game-instruction {
  cursor: pointer;
  width: 170px;
  height: 70px;
  /* 解决缩小图片后，图片变模糊 */
  image-rendering: -moz-crisp-edges;
  image-rendering: -moz-crisp-edges;
  image-rendering: -webkit-optimize-contrast;
  image-rendering: crisp-edges;
  -ms-interpolation-mode: nearest-neighbor;
}
.game-items {
  flex-wrap: wrap;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}
.game-items li {
  position: relative;
  width: 230px;
  height: 230px;
  background: #fff;
  border-radius: 30px;
  cursor: pointer;
}

.item-bg {
  width: 100%;
}
.item-img-box {
  width: 210px;
  height: 210px;
  border-radius: 20px;
  margin: 10px auto;
  padding: 10px;
  box-sizing: border-box;
}
.item-img-box img {
  border-radius: 20px;
  max-width: 100%;
  max-height: 100%;
  margin: 0 auto;
  /* 解决缩小图片后，图片变模糊 */
  image-rendering: -moz-crisp-edges;
  image-rendering: -moz-crisp-edges;
  image-rendering: -webkit-optimize-contrast;
  image-rendering: crisp-edges;
  -ms-interpolation-mode: nearest-neighbor;
}
li.item0 {
  margin: 0 60px 0 40px;
}
li.item0 p {
  background: rgb(254, 154, 154);
}
li.item1 {
  margin: 60px 60px 0 0;
}
li.item1 p {
  background: rgb(155, 151, 255);
}
li.item2 {
  margin: 20px 60px 0 0;
}
li.item2 p {
  background: rgb(243, 187, 131);
}
li.item3 {
  margin-right: 60px;
}
li.item3 p {
  background: rgb(53, 203, 205);
}
li.item4 {
  margin-right: 70px;
}
li.item4 p {
  background: rgb(103, 152, 255);
}
li.item5 {
  margin: 45px 70px 0 0;
}
li.item5 p {
  background: rgb(19, 190, 157);
}
li.item6 {
  margin: 40px 70px 0 0;
}
li.item6 p {
  background: rgb(228, 149, 158);
}
li.item7 p {
  background: rgb(182, 176, 78);
}

.close {
  opacity: 0;
}
.open {
  opacity: 1;
}
.mask-con {
  width: 738px;
  height: 542px;
  background-size: 100%;
  padding: 150px 80px 55px;
  box-sizing: border-box;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.mask-con .title {
  text-align: center;
  margin-bottom: 10px;
  font-size: 28px;
}
.mask-con p {
  font-size: 24px;
  line-height: 42px;
  text-align: left;
}
</style>
